export class TransitValidationError extends Error {
  constructor(message) {
    super(message);
    this.name = 'TransitValidationError';
  }
}

export function transitValidator(transit) {
  if (typeof transit.id !== 'number') {
    throw new TransitValidationError("Invalid transit data: 'id' should be a number.");
  }

  if (typeof transit.start_time !== 'string') {
    throw new TransitValidationError("Invalid transit data: 'start_time' should be a string.");
  }

  if (typeof transit.end_time !== 'string') {
    throw new TransitValidationError("Invalid transit data: 'end_time' should be a string.");
  }

  if (!Array.isArray(transit.meta_objects)) {
    throw new TransitValidationError("Invalid transit data: 'meta_objects' should be an array");
  }

  transit.meta_objects.forEach((meta) => {
    if (typeof meta.id !== 'number') {
      throw new TransitValidationError("Invalid meta_object data: 'id' should be a number.");
    }

    meta.classifications?.forEach((classification) => {
      if (typeof classification.value !== 'string' || typeof classification.type !== 'string') {
        throw new TransitValidationError('Invalid classification data.');
      }
    });

    if (meta.reading !== null
      && (typeof meta.reading.value !== 'string'
        || typeof meta.reading.type !== 'string')) {
      throw new TransitValidationError('Invalid reading data.');
    }
  });
}

export function transitsValidator(data) {
  if (typeof data.count !== 'number') {
    throw new TransitValidationError("Invalid data: 'count' should be a number.");
  }

  if (!Array.isArray(data.transits)) {
    throw new TransitValidationError("Invalid data: 'transits' should be an array.");
  }

  data.transits.forEach((transit) => {
    transitValidator(transit);
  });
}
