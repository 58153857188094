import { defineStore } from 'pinia';
import statsValidator from '@/validators/statsValidator';
import { transitsValidator, transitValidator, TransitValidationError } from '@/validators/transitValidator';
import metaObjectValidator from '@/validators/metaObjectValidator';
import { STATISTICAL_TYPE } from '@/utils/lookup';
import {
  getAPIData,
  getAPIDataNoErrorLog,
  getUrlData,
  putAPIData,
  postAPIData,
  deleteAPIData,
} from '@/utils/api';
import { apiUrl } from '@/../TransitViewerConfig';
import i18n from '@/locale/i18n';
import emitter from '@/utils/create_emitter';
import { httpStatus } from '@/utils/http_status';
import useUMStore from './UserManagementStore';

function getVideoFileNameFromContentDisposition(headers, defaultFileName) {
  const contentDisposition = headers.get('Content-Disposition');
  let filename = defaultFileName;
  if (contentDisposition) {
    const match = contentDisposition.match(/filename="?([^"]+)"?/);
    if (match) {
      [, filename] = match;
    }
  }
  return filename;
}

const useTransitStore = defineStore('transitStore', {
  state: () => ({
    facilities: [],
    facility: {},
    locations: {},
    transit: {},
    stats: {},
    statsTypes: {},
    streams: {},
    streamMap: new Map(),
    locationMap: new Map(),
    metaObject: {},
    metaObjectTypes: {},
    errors: {
      fetchStats: null,
      fetchTransits: null,
      fetchMetaObjects: null,
    },
  }),
  actions: {
    async fetchFacilities() {
      await getAPIData('/facilities', useUMStore().getUserToken())
        .then((response) => { this.facilities = response.content; }).catch();
    },
    async fetchFilteredFacilities(filterValues) {
      const queryParams = new URLSearchParams();
      if (filterValues.searchValue) {
        queryParams.append('search_value', filterValues.searchValue);
      }
      return getAPIData(`/facilities?${queryParams.toString()}`, useUMStore().getUserToken())
        .then((response) => response.content).catch();
    },
    async fetchLocation() {
      await getAPIData('/locations', useUMStore().getUserToken())
        .then((response) => {
          this.locations = response.content;
          this.locations.forEach((location) => {
            this.locationMap.set(location.id, location);
          });
        }).catch();
    },
    async fetchFilteredLocations(filterValues) {
      const queryParams = new URLSearchParams();
      if (filterValues.searchValue) {
        queryParams.append('search_value', filterValues.searchValue);
      }
      if (filterValues.facilityIds) {
        filterValues.facilityIds.forEach((facilityId) => queryParams.append('facility_id', facilityId));
      }
      return getAPIData(`/locations?${queryParams.toString()}`, useUMStore().getUserToken())
        .then((response) => response.content).catch();
    },
    cleanTransits() {
      this.transit = {};
    },
    async fetchTransits({
      sortingType = 'desc',
      sortAttribute = ['end_time'],
      initialTime = 0,
      finishTime = Math.floor(Date.now() / 1000),
      searchValue = '',
      pageNumber = 0,
      pageSize = 50,
      includeEmptyTransits = false,
      locations,
      transitTypes,
      transitDirection,
    } = {}) {
      this.errors = {};
      const queryParams = new URLSearchParams({
        sorting_type: sortingType,
        initial_time: initialTime,
        finish_time: finishTime,
        search_value: searchValue,
        page_number: pageNumber,
        page_size: pageSize,
        include_empty_transits: includeEmptyTransits,
      });
      if (Array.isArray(sortAttribute)) {
        sortAttribute.forEach((attribute) => {
          queryParams.append('sort_attribute', attribute);
        });
      } else {
        queryParams.append('sort_attribute', sortAttribute);
      }
      if (locations) {
        Object.values(locations).forEach((locationId) => queryParams.append('location_id', locationId));
      }
      if (transitTypes) {
        Object.values(transitTypes).forEach((transitType) => queryParams.append('transit_type', transitType));
      }
      if (transitDirection) {
        queryParams.append('direction', transitDirection);
      }
      await getAPIData(`/transits?${queryParams.toString()}`, useUMStore().getUserToken())
        .then((response) => {
          if (response.no_content) {
            console.warn('No transits returned from this query.');
            this.transit = '';
            return;
          }
          this.transit = response.content;
          transitsValidator(this.transit);
        })
        .catch(
          (validationError) => {
            if (!(validationError instanceof TransitValidationError)) {
              this.errors.fetchTransits = i18n.global.t('transitStore.errorFetchTransits');
              return;
            }
            console.error('Error with transit id', this.transit.id ?? 'unknown', '[', validationError.message, ']');
          },
        );
    },
    async fetchTransit(transitId, {
      searchValue = '',
      locations,
    } = {}) {
      this.errors = {};
      const queryParams = new URLSearchParams({
        search_value: searchValue,
      });
      if (locations) {
        Object.values(locations).forEach((locationId) => queryParams.append('location_id', locationId));
      }
      return getAPIDataNoErrorLog(`/transits/${transitId}?${queryParams.toString()}`, useUMStore().getUserToken(), {})
        .then((response) => {
          if (response.no_content) {
            return null;
          }
          const transit = response.content;
          transitValidator(transit);
          return transit;
        })
        .catch(
          (validationError) => {
            if (!(validationError instanceof TransitValidationError)) {
              this.errors.fetchTransits = i18n.global.t('transitStore.errorFetchTransits');
              return;
            }
            console.error('Error with transit id', this.transit.id ?? 'unknown', '[', validationError.message, ']');
          },
        );
    },
    async fetchTransitsReport({
      sortingType = 'desc',
      sortAttribute = ['end_time'],
      initialTime = 0,
      finishTime = Math.floor(Date.now() / 1000),
      searchValue = '',
      pageNumber = 0,
      pageSize = 50,
      locations,
      transitTypes,
      reportDict,
    } = {}) {
      this.errors = {};
      const queryParams = new URLSearchParams({
        sorting_type: sortingType,
        initial_time: initialTime,
        finish_time: finishTime,
        search_value: searchValue,
        page_number: pageNumber,
        page_size: pageSize,
        report_type: reportDict.report_type,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      });
      if (Array.isArray(sortAttribute)) {
        sortAttribute.forEach((attribute) => {
          queryParams.append('sort_attribute', attribute);
        });
      } else {
        queryParams.append('sort_attribute', sortAttribute);
      }
      if (locations) {
        Object.values(locations).forEach((locationId) => queryParams.append('location_id', locationId));
      }
      if (transitTypes) {
        Object.values(transitTypes).forEach((transitType) => queryParams.append('transit_type', transitType));
      }
      return getAPIData(`/transits/report?${queryParams.toString()}`, useUMStore().getUserToken(), {
        headers: { Accept: reportDict.header }, responseType: 'blob',
      }).then((response) => {
        const fileName = getVideoFileNameFromContentDisposition(response.headers, '');
        return {
          blob: response.blob,
          filename: fileName,
        };
      }).catch();
    },
    async fetchImageInline(streamId, imageId) {
      return getAPIData(`/image/${streamId}/${imageId}?inline=true`, useUMStore().getUserToken(), {
        headers: { Accept: 'image/jpeg' }, responseType: 'blob',
      }).then((response) => response.blob).catch(() => { throw new Error('image not found'); });
    },
    async fetchImage(streamId, imageId) {
      const queryParams = new URLSearchParams({
        inline: false,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      });
      return getAPIData(`/image/${streamId}/${imageId}?${queryParams.toString()}`, useUMStore().getUserToken(), {
        headers: { Accept: 'image/jpeg', 'Content-type': 'application/octet-stream' }, responseType: 'blob',
      }).then((response) => {
        const fileName = getVideoFileNameFromContentDisposition(response.headers, 'detection_image.jpeg');
        return {
          blob: response.blob,
          filename: fileName,
        };
      }).catch(() => { throw new Error('image not found'); });
    },
    async fetchVideo(streamId, videoId) {
      const queryParams = new URLSearchParams({
        inline: false,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      });
      return getAPIData(`/video/${streamId}/${videoId}?${queryParams.toString()}`, useUMStore().getUserToken(), {
        headers: { Accept: 'video/mp4', 'Content-type': 'application/octet-stream' }, responseType: 'blob',
      }).then((response) => {
        if (!response.ok) {
          if (response.status === httpStatus.TOO_EARLY) {
            throw new Error(i18n.global.t('transitStore.errorFetchVideoNotDownloadedYet'), { cause: response.status });
          }
          throw new Error(i18n.global.t('transitStore.errorFetchVideo'), { cause: response.status });
        }
        const fileName = getVideoFileNameFromContentDisposition(response.headers, 'transit_video.mp4');
        return {
          blob: response.blob,
          filename: fileName,
        };
      }).catch();
    },
    async fetchVideoInline(streamId, videoId) {
      return getAPIData(`/video/${streamId}/${videoId}?inline=true`, useUMStore().getUserToken(), {
        headers: { Accept: 'video/mp4' }, responseType: 'blob',
      }).then((response) => {
        if (!response.ok) {
          if (response.status === httpStatus.TOO_EARLY) {
            throw new Error(i18n.global.t('transitStore.errorFetchVideoNotDownloadedYet'), { cause: response.status });
          }
          throw new Error(i18n.global.t('transitStore.errorFetchVideo'), { cause: response.status });
        }
        return response.blob;
      }).catch();
    },
    async fetchClientLogo() {
      return getUrlData(new URL('client_logo.png', apiUrl()).href, useUMStore().getUserToken(), {
        headers: { Accept: 'image/png' }, responseType: 'blob',
      }).then((response) => URL.createObjectURL(response.blob)).catch();
    },
    async fetchStatsTypes() {
      await getAPIData('/stats/types', useUMStore().getUserToken())
        .then((response) => {
          this.statsTypes = {};
          const statTypes = response.content;
          statTypes.types?.forEach((statType) => {
            if (statType in STATISTICAL_TYPE) {
              this.statsTypes[statType] = STATISTICAL_TYPE[statType];
            } else {
              console.error('Type', statType, 'does not exist');
            }
          });
        }).catch();
    },
    async fetchStats({
      initialTime = 0,
      finishTime = Math.floor(Date.now() / 1000),
      statsTypes = [],
      locations,
    } = {}) {
      const queryParams = new URLSearchParams({
        initial_time: initialTime,
        finish_time: finishTime,
      });
      if (Array.isArray(statsTypes)) {
        statsTypes.forEach((attribute) => {
          queryParams.append('statistical_type', attribute);
        });
      } else {
        queryParams.append('statistical_type', statsTypes);
      }
      if (locations) {
        Object.values(locations).forEach((locationId) => queryParams.append('location_id', locationId));
      }
      await getAPIData(`/stats?${queryParams.toString()}`, useUMStore().getUserToken())
        .then((response) => {
          statsValidator(response.content);
          this.stats = response.content;
          this.errors.fetchStats = null;
        }).catch(() => { this.errors.fetchStats = i18n.global.t('transitStore.errorFetchStats'); });
    },
    async fetchMetaObjects({
      sortingType = 'desc',
      sortAttribute = ['end_time'],
      initialTime = 0,
      finishTime = Math.floor(Date.now() / 1000),
      searchValue = '',
      metaObjectType = [],
      pageNumber = 0,
      pageSize = 50,
      confidenceRange = [0, 1],
      locations = {},
    } = {}) {
      this.errors = {};
      const queryParams = new URLSearchParams({
        sorting_type: sortingType,
        initial_time: initialTime.valueOf(),
        finish_time: finishTime.valueOf(),
        search_value: searchValue,
        page_number: pageNumber,
        page_size: pageSize,
      });
      if (confidenceRange) {
        queryParams.append('minimum_confidence', confidenceRange[0]);
        queryParams.append('maximum_confidence', confidenceRange[1]);
      }
      if (locations) {
        Object.values(locations).forEach((locationId) => queryParams.append('location_id', locationId));
      }
      if (Array.isArray(sortAttribute)) {
        sortAttribute.forEach((attribute) => {
          queryParams.append('sort_attribute', attribute);
        });
      } else {
        queryParams.append('sort_attribute', sortAttribute);
      }
      if (Array.isArray(metaObjectType) && metaObjectType) {
        metaObjectType.forEach((attribute) => {
          queryParams.append('meta_object_type', attribute);
        });
      } else {
        queryParams.append('meta_object_type', metaObjectType);
      }
      await getAPIData(`/meta_objects?${queryParams.toString()}`, useUMStore().getUserToken())
        .then((response) => {
          if (response.no_content) {
            this.metaObject = { meta_objects: [], count: 0 };
          } else {
            this.metaObject = response.content;
            metaObjectValidator(this.metaObject);
          }
        }).catch(() => { this.errors.fetchMetaObjects = i18n.global.t('transitStore.errorFetchMetaObjects'); });
    },
    async fetchMetaObjectTypes() {
      await getAPIData('/meta_objects/types', useUMStore().getUserToken())
        .then((response) => {
          this.metaObjectTypes = {};
          const metaObjectTypes = response.content;
          metaObjectTypes.types?.forEach((metaObjectType) => {
            if (metaObjectType in STATISTICAL_TYPE) {
              this.metaObjectTypes[metaObjectType] = STATISTICAL_TYPE[metaObjectType];
            } else console.error('Type', metaObjectType, 'does not exist');
          });
        }).catch();
    },
    async fetchStreams() {
      await getAPIData('/streams', useUMStore().getUserToken())
        .then((response) => {
          this.streams = response.content;
          this.streams.forEach((stream) => {
            this.streamMap.set(stream.id, stream);
          });
        }).catch();
    },
    async fetchFilteredStreams(filterValues) {
      const queryParams = new URLSearchParams();
      if (filterValues.searchValue) {
        queryParams.append('search_value', filterValues.searchValue);
      }
      if (filterValues.locationIds) {
        filterValues.locationIds.forEach((locationId) => queryParams.append('location_id', locationId));
      }
      return getAPIData(`/streams?${queryParams.toString()}`, useUMStore().getUserToken())
        .then((response) => response.content).catch(() => []);
    },
    async putReadingValue({
      readingId = -1,
      newValue = '',
    } = {}) {
      const queryParams = new URLSearchParams({ new_value: newValue });
      return putAPIData(`/reading/${readingId}?${queryParams.toString()}`, useUMStore().getUserToken())
        .then((response) => response.ok && response.no_content);
    },
    async putClassificationValue({
      classificationId = -1,
      newValue = '',
    } = {}) {
      const queryParams = new URLSearchParams({ new_value: newValue });
      return putAPIData(`/classification/${classificationId}?${queryParams.toString()}`, useUMStore().getUserToken())
        .then((response) => response.ok && response.no_content);
    },
    async addFacility(facilityInfo) {
      return postAPIData('/facilities', useUMStore().getUserToken(), { body: JSON.stringify(facilityInfo) })
        .then((response) => {
          emitter.emit('updated_facilities');
          return response;
        });
    },
    async editFacility(facilityInfo) {
      const facilityUrl = `/facilities/${encodeURIComponent(facilityInfo.id)}`;
      return putAPIData(facilityUrl, useUMStore().getUserToken(), { body: JSON.stringify(facilityInfo) })
        .then((response) => {
          emitter.emit('updated_facilities');
          return response;
        });
    },
    async deleteFacility(facilityInfo) {
      return deleteAPIData(`/facilities/${facilityInfo.id}`, useUMStore().getUserToken())
        .then((response) => {
          const deleteSuccessfull = response.ok && response.no_content;
          if (deleteSuccessfull) {
            emitter.emit('updated_facilities');
          }
          return deleteSuccessfull;
        });
    },
    async addLocation(locationInfo) {
      return postAPIData('/locations', useUMStore().getUserToken(), { body: JSON.stringify(locationInfo) })
        .then((response) => {
          emitter.emit('updated_locations');
          return response;
        });
    },
    async editLocation(locationInfo) {
      const locationUrl = `/locations/${encodeURIComponent(locationInfo.id)}`;
      return putAPIData(locationUrl, useUMStore().getUserToken(), { body: JSON.stringify(locationInfo) })
        .then((response) => {
          emitter.emit('updated_locations');
          return response;
        });
    },
    async deleteLocation(locationInfo) {
      return deleteAPIData(`/locations/${locationInfo.id}`, useUMStore().getUserToken())
        .then((response) => {
          const deleteSuccessfull = response.ok && response.no_content;
          if (deleteSuccessfull) {
            emitter.emit('updated_locations');
          }
          return deleteSuccessfull;
        });
    },
    async addSource(sourceInfo) {
      return postAPIData('/streams', useUMStore().getUserToken(), { body: JSON.stringify(sourceInfo) })
        .then((response) => {
          emitter.emit('updated_sources');
          return response;
        });
    },
    async editSource(sourceInfo) {
      const sourceEndpointUrl = `/streams/${sourceInfo.id}`;
      return putAPIData(sourceEndpointUrl, useUMStore().getUserToken(), { body: JSON.stringify(sourceInfo) })
        .then((response) => {
          emitter.emit('updated_sources');
          return response;
        });
    },
    async deleteSource(sourceInfo) {
      return deleteAPIData(`/streams/${sourceInfo.id}`, useUMStore().getUserToken())
        .then((response) => {
          const deleteSuccessfull = response.ok && response.no_content;
          if (deleteSuccessfull) {
            emitter.emit('updated_sources');
          }
          return deleteSuccessfull;
        });
    },
  },
});

export default useTransitStore;
