<template>
  <div class="relative m-3 p-4 flex items-center justify-between
  w-1/2 bg-gray-100 mx-auto rounded-2xl">
    <div class="flex gap-8 items-center">
      <CardLabel :cardLabelText="this.$t(this.getStatsTypeName(this.section.type))" :bold="true"/>
      <img
          v-if="this.section.icon"
          :src="sectionIcon(this.section.icon)"
          class="w-12 h-12"
          alt="Meta Object Icon"
        >
      <div
        v-for="(valueElt, index) in this.section.values"
        :key="index"
        class="flex">
        <div contenteditable="true"
          @keydown="preventReturn"
          @blur="updateValue($event, index)"
          class="flex hover:bg-gray-200 cursor-pointer rounded-md p-1 -m-1"
          spellcheck="false">
          <i v-if="valueElt.edited">
            <svg width="12px" height="12px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
              <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" stroke="#CCCCCC" stroke-width="0.048"></g>
              <g id="SVGRepo_iconCarrier">
                <path d="M13 21H21" stroke="#5e5e5e" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                <path d="M20.0651 7.39423L7.09967 20.4114C6.72438 20.7882 6.21446 21 5.68265 21H4.00383C3.44943 21 3 20.5466 3
                19.9922V18.2987C3 17.7696 3.20962 17.2621 3.58297 16.8873L16.5517 3.86681C19.5632 1.34721 22.5747 4.87462 20.0651
                7.39423Z" stroke="#5e5e5e" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                <path d="M15.3097 5.30981L18.7274 8.72755" stroke="#5e5e5e" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
              </g>
            </svg>
          </i>
          {{ valueElt.value.replaceAll('_', ' ') ?? '' }}
        </div>
        <img
          :src="confidenceIcon(valueElt.confidence)"
          class="w-5 h-5 self-center ml-2"
          alt="confidence icon"
          :title=confidenceRounded(valueElt.confidence)>
      </div>
    </div>
    <div v-if="hasSomeImage" class="bg-gray-200 rounded-xl px-2 py-1
        transition-colors duration-200 ease-in-out transform hover:bg-gray-300 hover:shadow-lg
        hover:scale-105 w-fit"
        @click="openPopup()">
      <i class="w-8 h-8"><img :src="sectionIcon('forensicImageIcon')" class="w-8 h-8" alt="camera icon"></i>
    </div>
  </div>
  <PopupImage
    :show="showPopup"
    :transitSections="[this.section]"
    @close="showPopup = false" />
</template>

<script>
import useTransitStore from '@/stores/TransitStore';
import { VEHICLE_INFO_TYPES } from '@/utils/types';
import { getConfidenceIcon, getIconName, getIcon } from '@/utils/icons';
import valueToPercent from '@/utils/math';
import { getStatisticalTypeName } from '@/utils/lookup';

import PopupImage from '@/components/PopUpImage.vue';
import CardLabel from '@/components/CardLabel.vue';

export default {
  name: 'ObjectCard',
  props: ['metaObject'],
  components: {
    PopupImage,
    CardLabel,
  },
  setup() {
    const transitStore = useTransitStore();
    const confidenceIcon = getConfidenceIcon;
    const confidenceRounded = valueToPercent;
    const getStatsTypeName = getStatisticalTypeName;
    const sectionIcon = getIcon;
    return {
      transitStore,
      confidenceIcon,
      confidenceRounded,
      sectionIcon,
      getStatsTypeName,
    };
  },
  data() {
    return {
      vehicleInfoTypes: VEHICLE_INFO_TYPES,
      section: {},
      showPopup: false,
    };
  },
  computed: {
    hasSomeImage() {
      return (this.section.images?.length > 0);
    },
  },
  methods: {
    async openPopup() {
      this.showPopup = true;
    },
    async updateValue(event, index) {
      const updatedValue = event.target.innerText;
      const currentSection = this.section.values[index];
      if (currentSection.value === updatedValue) return;
      let putResult = false;
      if (currentSection.db_id && currentSection.db_id !== -1) {
        if (currentSection.value_type === 'reading') {
          putResult = await this.transitStore.putReadingValue({ readingId: currentSection.db_id, newValue: updatedValue });
        } else {
          putResult = await this.transitStore.putClassificationValue({ classificationId: currentSection.db_id, newValue: updatedValue });
        }
      }
      if (putResult) {
        currentSection.edited = true;
        currentSection.value = updatedValue;
        this.emitter.emit('show-toast', { toastType: 'info', toastText: this.$t('transitCard.successfulEdit') });
      } else {
        const replaceValue = currentSection.value;
        currentSection.value = replaceValue;
        this.emitter.emit('show-toast', { toastType: 'error', toastText: this.$t('transitCard.errorOnEdit') });
      }
    },
    preventReturn(event) {
      const keyCode = (event.keyCode ? event.keyCode : event.which);
      if (keyCode === 13) {
        event.preventDefault();
        event.target.blur();
      }
    },
    setSectionTypeAndIcon(metaObject, section) {
      const newSection = { ...section };
      const readingType = metaObject?.reading?.type;
      const classificationType = metaObject?.classifications?.[0]?.type;
      const classificationValue = metaObject?.classifications?.[0]?.value;
      if (metaObject) {
        if (metaObject.child_meta_objects !== null
        && typeof metaObject.child_meta_objects !== 'undefined'
        && metaObject.child_meta_objects.length > 0) {
          metaObject.child_meta_objects.forEach((childMetaObject) => {
            newSection.childMetaObjects.push(this.getSection(childMetaObject));
          });
        }
      }
      newSection.type = readingType ?? classificationType ?? '';
      newSection.typeConfidence = metaObject?.reading?.confidence ?? metaObject?.classifications?.[0]?.confidence ?? '';
      newSection.icon = getIconName(newSection.type, classificationValue);
      if (newSection.type === 'vehicleclassification'
       || newSection.type === 'vehicledetection') newSection.type = 'vehicle';
      if (this.vehicleInfoTypes.includes(newSection.type)) newSection.type = 'vehicle information';
      if (newSection.type.includes('universal')) newSection.type = newSection.type.replace('universal', '');
      return newSection;
    },

    setSectionValues(metaObject, section) {
      const newSection = { ...section };
      newSection.section_id = metaObject.id;
      newSection.values = [];
      metaObject?.classifications?.forEach((classification) => {
        const content = {
          db_id: classification?.id,
          value: classification?.value ?? '',
          value_type: 'classification',
          confidence: classification?.confidence ?? '',
          edited: classification?.edited,
          check_digit_passed: true,
        };
        newSection.values.push(content);
      });
      if (metaObject?.reading) {
        const content = {
          db_id: metaObject.reading.id,
          value: metaObject.reading.value ?? '',
          value_type: 'reading',
          confidence: metaObject.reading.confidence ?? '',
          edited: metaObject.reading.edited,
          check_digit_passed: true,
          check_digit_str: '',
        };
        metaObject.reading.validations?.forEach((validation) => {
          if (validation.value === 1) {
            content.check_digit_passed = false;
            content.check_digit_str
            += this.$t('objectCard.checkDigitFailed', { validationType: validation.type, confidence: valueToPercent(content.confidence) });
          }
        });
        newSection.values.push(content);
      }

      return newSection;
    },
    setDetectionAndImages(metaObject, section) {
      const newSection = { ...section };

      let detectionTime = 0;
      let detectionRoi = {};
      let detectionSourceId = '';
      let detectionTypeName = '';
      let additionalStreamImages = [];

      const getImageObject = (image, selectedCriteria) => (
        {
          criteria: selectedCriteria,
          time: detectionTime,
          roi: (image.stream_id === detectionSourceId) ? detectionRoi : null,
          id: image.id,
          stream_id: image.stream_id,
          stream_alias: image.stream_alias,
          width: image.width,
          height: image.height,
        }
      );

      const processAdditionalStreamImages = (image) => {
        image.criteria_list?.forEach((selectedCriteria) => {
          if (selectedCriteria !== 'additional_stream') return;
          additionalStreamImages.push(getImageObject(image, selectedCriteria));
        });
      };

      const processImages = (image) => {
        image.criteria_list?.forEach((selectedCriteria) => {
          if (selectedCriteria === 'additional_stream') return;
          const imageObject = { ...getImageObject(image, selectedCriteria) };
          imageObject.detectionTypeName = detectionTypeName;
          imageObject.additionalStreamImages = [...additionalStreamImages];
          newSection.images.push(imageObject);
        });
      };

      const processDetection = (metaobjectDetection) => {
        detectionTime = metaobjectDetection.time;
        detectionRoi = {
          x: metaobjectDetection.x,
          y: metaobjectDetection.y,
          width: metaobjectDetection.width,
          height: metaobjectDetection.height,
        };
        detectionSourceId = metaobjectDetection.stream_id;
        additionalStreamImages = [];
        metaobjectDetection.images?.forEach(processAdditionalStreamImages);
        metaobjectDetection.images?.forEach(processImages);
      };
      newSection.images = [];

      metaObject?.classifications?.forEach((classification) => {
        detectionTypeName = this.getStatsTypeName(classification.type);
        classification.detections?.forEach(processDetection);
      });

      if (metaObject.reading) {
        detectionTypeName = this.getStatsTypeName(metaObject.reading.type);
        metaObject.reading.detections?.forEach(processDetection);
      }
      return newSection;
    },
    updateSection() {
      let section = {
        type: '',
        typeConfidence: '',
        icon: '',
        values: [],
      };
      section = this.setSectionTypeAndIcon(this.metaObject, section);
      section = this.setSectionValues(this.metaObject, section);
      section = this.setDetectionAndImages(this.metaObject, section);
      this.section = section;
    },
  },
  beforeMount() {
    this.updateSection();
  },
  watch: {
    metaObject() {
      this.updateSection();
    },
  },
};
</script>
